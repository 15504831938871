import { render, staticRenderFns } from "./MenuComponent.vue?vue&type=template&id=33e309b8&scoped=true"
import script from "./MenuComponent.vue?vue&type=script&lang=js"
export * from "./MenuComponent.vue?vue&type=script&lang=js"
import style0 from "./MenuComponent.vue?vue&type=style&index=0&id=33e309b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../Users/florent.sirigu/AppData/Roaming/nvm/v20.13.1/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e309b8",
  null
  
)

export default component.exports